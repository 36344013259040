



import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Home2 from './Home2';
import HomeCurrents from './HomeCurrents';
import Text from './Text';



function App() {
  
  return (
    <div className="App" style={{position: 'relative'}}>
      <Home />
      {/* <Home2 /> */}
      {/* <HomeCurrents /> */}
   <Text />

    </div>
  );
}

export default App;
